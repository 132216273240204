import styled from 'styled-components';
import { media } from "styled-bootstrap-grid"

export const StyledArticleList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 16px 0 64px;
  list-style-type: none;
`;

export const FeatureHero = styled.div`
  padding: 64px 32px 32px;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  position: relative;
  color: hsla(0, 0%, 0%, 0.8);
  h1 {
    font-size: 50px;
    font-weight: 500;
  }
  a {
    color: white;
    text-decoration: none;
    transition: 0.2s;
    span {
      text-decoration: underline;
    }
  }
  .gatsby-image-wrapper {
    margin-top: auto;
  }
  h2 {
    margin-bottom: 16px;
  }
  span, a {
    font-size: 14px;
  }
`;

export const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 32px 64px;
  font-size: 18px;
  background: transparent;
  button {
    padding: 8px 16px;
    margin: 0 8px;
    transition: 0.2s;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 2px;
     ${media.md`
      margin: 0 32px;
    `}
    &:disabled {
      border-color: rgba(0, 0, 0, 0.2);
    }
    &:not(:disabled):hover {
      cursor: pointer;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 0px 1px rgba(0,0,0,0.24);
    }
  }
  div {
    display: none;
    ${media.md`
      display: block;
    `}
  }
`
