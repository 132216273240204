import React, { useEffect, useState } from "react"
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {
  FeatureHero,
  PaginationWrap,
} from "./styles";
import Card from "../../components/Card"
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "styled-bootstrap-grid";

const ArticleList = () => {
  const [ currentPage, setCurrentPage ] = useState(1);
  const data = useStaticQuery(graphql`
    query {
      allWpPost(filter: {categories: {nodes: {elemMatch: {name: {ne: "Custom Post Type"}}}}}) {
        edges {
          node {
            excerpt
            title
            slug
            id
            date(formatString: "YYYY-MM-DD")
            featuredImage {
              node {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)
  const { allWpPost: { edges } } = data;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage])

  return (
    <Layout>
      <Seo
        title="All blog posts"
        description="A listing of all blog posts on the website."
      />
      <Container fluid>
        <Row>
          <Col noGutter>
            <FeatureHero>
              <h1>All Blog Posts</h1>
            </FeatureHero>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          {edges?.sort((a, b) => new Date(b?.node?.date) - new Date(a?.node?.date) )?.map(({ node }, i) => {
            if ((i + 1) >= currentPage && (i + 1) <= currentPage + 11) {
              return (
                <Col col={12} md={4} key={node.id}>
                  <Card
                    link={node?.slug}
                    img={node?.featuredImage?.node?.localFile?.publicURL}
                    excerpt={node?.excerpt}
                    title={node?.title}
                    date={node?.date}
                  />
                </Col>
              )
            }
            return null;
          })}
        </Row>
        <Row>
          <Col col={12}>
            <PaginationWrap>
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage <= 12 ? 1 : currentPage - 12)}
                >
                Prev
              </button>
              <div>
                Page {Math.ceil((currentPage + 1) / 12)} of {Math.ceil(edges.length / 12)}
              </div>
              <button
                disabled={currentPage >= (edges.length - 12)}
                onClick={() => setCurrentPage(
                  currentPage >= (edges.length - 12) ? edges.length : currentPage + 12)
                }
              >
                Next
              </button>
            </PaginationWrap>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
};

export default ArticleList;
